import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import About from "../components/frontpage/About"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/shared/Button"

const Banner = () => {
  return (
    <header className="relative h-[70vh]">
      <div className="absolute top-0 left-0 z-10 text-white flex flex-col justify-center h-[70vh] mx-4 md:mx-10 lg:mx-20 items-start">
        <h1 className="text-4xl lg:text-6xl font-extrabold uppercase">
          Voice Lessons in Norfolk County
        </h1>
        <p className="text-2xl md:text-4xl font-light mb-5">
          in the voice studio of Julie Ludwig
        </p>
        <p className="mt-2 mb-10 before:content-[''] before:block before:w-24 before:border-t before:mb-10">
          classical
          <span className="font-extrabold mx-2 text-2xl relative top-1">
            &bull;
          </span>
          musical theatre
          <span className="font-extrabold mx-2 text-2xl relative top-1">
            &bull;
          </span>
          rcm prep
        </p>
        <div className="flex gap-3">
          <Button
            outline
            className="text-white hover:bg-white hover:text-indigo-600"
          >
            <Link to="/policies">Rate &amp; Policies</Link>
          </Button>
          <Button
            outline
            className="text-white hover:bg-white hover:text-indigo-600"
          >
            <Link to="/contact">Contact</Link>
          </Button>
        </div>
      </div>
      <StaticImage
        src="../images/home-banner.jpg"
        className="z-0 object-cover overflow-hidden h-full"
      />
    </header>
  )
}

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Banner />
    <About />
  </Layout>
)

export default IndexPage
