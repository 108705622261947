import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../shared/Button"

const About = () => {
  return (
    <div className="py-32 mx-4 md:mx-10 flex flex-col justify-center gap-8 md:gap-16 md:flex-row">
      <StaticImage
        src="../../images/julie-headshot.jpg"
        className="m-auto md:m-0 rounded-full w-60 h-60 lg:w-72 lg:h-72 xl:w-96 xl:h-96 place-self-end self-start flex-grow-0 aspect-square"
      />
      <div className="flex-grow-1">
        <h2 className="text-4xl mb-5 text-indigo-500">About Julie</h2>
        <p className="mb-4 text-xl max-w-sm lg:max-w-xl text-slate-500">
          Welcome to the voice studio of soprano Julie Ludwig. Julie brings
          extensive performance and teaching experience and a passion for the
          vocal arts to her teaching.
        </p>
        <p className="mb-2 max-w-md md:max-w-sm lg:max-w-xl">
          All singing lessons are taught at my home studio near the border of
          Brant and Norfolk Counties, and is accessible from Brantford,
          Waterford, Simcoe, Mt. Pleasant, Burford, and Norwich.
        </p>

        <p className="mb-2 max-w-md md:max-w-sm lg:max-w-xl">
          Julie’s students have been accepted into undergraduate and graduate
          programs in music, have performed leading roles in operas and
          musicals, and have received awards at local and provincial music
          festivals. Additionally, her students have attained high grades in
          voice and theory exams with the Royal Conservatory of Music.
        </p>
        <p className="mb-10 max-w-md md:max-w-sm lg:max-w-xl">
          Julie has a Bachelor of Music degree (Honors Music History) and a
          Master of Music degree in Vocal Performance from Western University.
        </p>
        <Button outline className="text-indigo-500 border-indigo-500">
          <Link to="/about">More</Link>
        </Button>
      </div>
    </div>
  )
}
export default About
